import { useCallback, useEffect, useState } from "react";

import "./WordleGame.css";
import { toast, Toaster } from "sonner";

type WordleGameProps = {
	_word: string;
	_wordList: Array<string>;
};

const WordleGame: React.FC<WordleGameProps> = ({ _word, _wordList }) => {
	const [busy, setBusy] = useState(false);
	const [attempts, setAttempts] = useState<string[]>([]);
	const [keyboard, setKeyboard] = useState<Record<string, string>>({
		Q: "",
		W: "",
		E: "",
		R: "",
		T: "",
		Y: "",
		U: "",
		I: "",
		O: "",
		P: "",
		A: "",
		S: "",
		D: "",
		F: "",
		G: "",
		H: "",
		J: "",
		K: "",
		L: "",
		Z: "",
		X: "",
		C: "",
		V: "",
		B: "",
		N: "",
		M: "",
	});

	const [board, setBoard] = useState<string[][]>([
		["", "", "", "", ""],
		["", "", "", "", ""],
		["", "", "", "", ""],
		["", "", "", "", ""],
		["", "", "", "", ""],
		["", "", "", "", ""],
	]);

	const [column, setColumn] = useState<number>(0);
	const [row, setRow] = useState<number>(0);

	const [isWord, setIsWord] = useState<boolean>(true);

	const maxAttempts = 6;

	const handleSubmit = useCallback(() => {
		const guess = board[row].join("");

		if (guess.length !== _word.length) return;

		if (!(_wordList.includes(guess))) {
			setIsWord((prevState) => false);
			setTimeout(() => setIsWord(true), 200);
			toast("Not in the word list.");
			return;
		}

		const updatedKeyboard = { ...keyboard };
		board[row].forEach((e) => {
			const upperE: string = e.toUpperCase();
			updatedKeyboard[upperE] = _word.includes(e) ? "correct" : "used";
		});

		setKeyboard(updatedKeyboard);

		setAttempts([...attempts, guess]);

		if (guess.toLowerCase() === _word.toLowerCase()) {
			setRow((prevState) => maxAttempts + 1);
			setColumn((prevState) => _word.length + 1);
			toast("You beat me. You are the superior being.");
		} else if (attempts.length + 1 === maxAttempts) {
			toast(`The word was ${_word.toUpperCase().toString()}, you didn't save the world today.`);
		}

		setRow((prevState) => (prevState < maxAttempts ? prevState + 1 : prevState));
		setColumn((prevState) => 0);
	}, [_wordList, _word, attempts, board, keyboard, row]);

	const keyPress = useCallback(
		(input: string) => {
			if (column > _word.length || row >= maxAttempts) return;
			const alphabeticRegex = /^[a-zA-Z]$/;

			if (alphabeticRegex.test(input)) {
				setBoard((prevBoard) => {
					const newBoard = [...prevBoard];

					newBoard[row][column] = input.toLowerCase();
					return newBoard;
				});
				if (column < _word.length - 1) setColumn((prevState) => prevState + 1);
			} else if (input === "Enter") {
				if(isWord) handleSubmit();
			} else if (input === "Backspace") {
				setBoard((prevBoard) => {
					const newBoard = [...prevBoard];
					if (newBoard[row][column] === "") {
						if (column > 0) {
							newBoard[row][column - 1] = "";
							setColumn((prevCol) => prevCol - 1);
						} else {
							newBoard[row][column] = "";
						}
					} else {
						newBoard[row][column] = "";
					}
					return newBoard;
				});
			}
		},
		[isWord, column, row, _word, handleSubmit]
	);

	useEffect(() => {
		const handleKeyUp = (event: KeyboardEvent) => {
			if (busy) return;
			setBusy(true);
			keyPress(event.key);
			setBusy(false);
		}

		document.addEventListener("keydown", handleKeyUp);
		return () => document.removeEventListener("keydown", handleKeyUp);
	}, [busy, keyPress]);

	return (
		<div id="wordle-game-board">
			<Toaster />
			<div id="board">
				{Array.from(Array(maxAttempts)).map((_, r) => {
					let guess: Array<string> = [];
					let wordArray: Array<string> = [];
					let res: Array<number> = [];
					if (r < row) {
						guess = board[r];
						wordArray = _word.split("");
						res = Array(_word.length).fill(0);

						// Exact matches
						for (let i = 0; i < _word.length; i++) {
							if (guess[i] === wordArray[i]) {
								res[i] = 1;
								delete wordArray[i]; //Set to undefined
							}
						}

						// Correct matches
						for (let i = 0; i < _word.length; i++) {
							if (wordArray[i] !== undefined) {
								if (guess.includes(wordArray[i])) {
									if (res[guess.indexOf(wordArray[i])] === 0) res[guess.indexOf(wordArray[i])] = 2;
									else res[guess.indexOf(wordArray[i], guess.indexOf(wordArray[i]) + 1)] = 2;
									delete wordArray[i];
								}
							}
						}

						return (
							<p key={r}>
								{Array.from(Array(_word.length)).map((_, c) => {
									// TODO: _word analysis here
									if (res[c] === 1)
										return (
											<span className={"exact"} key={`${r}-${c}`}>
												{board[r][c]}
											</span>
										);
									else if (res[c] === 2)
										return (
											<span className={"correct"} key={`${r}-${c}`}>
												{board[r][c]}
											</span>
										);
									else
										return (
											<span className={"miss"} key={`${r}-${c}`}>
												{board[r][c]}
											</span>
										);
								})}
							</p>
						);
					} else if (r === row)
						return (
							<p key={r}>
								{Array.from(Array(_word.length)).map((_, c) => {
									if (board[r] !== undefined && board[r][c] !== undefined) {
										return (
											<span className={isWord ? "" : "not-a-word"} key={`${r}-${c}`}>
												{board[r][c]}
											</span>
										);
									} else {
										return <span key={`${r}-${c}`}></span>;
									}
								})}
							</p>
						);
					else
						return (
							<p key={r}>
								{Array.from(Array(_word.length)).map((_, c) => {
									return <span key={`${r}-${c}`}></span>;
								})}
							</p>
						);
				})}
			</div>
			<div id="keyboard">
				<div>
					<button className={keyboard.Q} onClick={() => keyPress("Q")}>
						Q
					</button>
					<button className={keyboard.W} onClick={() => keyPress("W")}>
						W
					</button>
					<button className={keyboard.E} onClick={() => keyPress("E")}>
						E
					</button>
					<button className={keyboard.R} onClick={() => keyPress("R")}>
						R
					</button>
					<button className={keyboard.T} onClick={() => keyPress("T")}>
						T
					</button>
					<button className={keyboard.Y} onClick={() => keyPress("Y")}>
						Y
					</button>
					<button className={keyboard.U} onClick={() => keyPress("U")}>
						U
					</button>
					<button className={keyboard.I} onClick={() => keyPress("I")}>
						I
					</button>
					<button className={keyboard.O} onClick={() => keyPress("O")}>
						O
					</button>
					<button className={keyboard.P} onClick={() => keyPress("P")}>
						P
					</button>
				</div>
				<div>
					<button className={keyboard.A} onClick={() => keyPress("A")}>
						A
					</button>
					<button className={keyboard.S} onClick={() => keyPress("S")}>
						S
					</button>
					<button className={keyboard.D} onClick={() => keyPress("D")}>
						D
					</button>
					<button className={keyboard.F} onClick={() => keyPress("F")}>
						F
					</button>
					<button className={keyboard.G} onClick={() => keyPress("G")}>
						G
					</button>
					<button className={keyboard.H} onClick={() => keyPress("H")}>
						H
					</button>
					<button className={keyboard.J} onClick={() => keyPress("J")}>
						J
					</button>
					<button className={keyboard.K} onClick={() => keyPress("K")}>
						K
					</button>
					<button className={keyboard.L} onClick={() => keyPress("L")}>
						L
					</button>
				</div>
				<div>
					<button className={keyboard.Z} onClick={() => keyPress("Z")}>
						Z
					</button>
					<button className={keyboard.X} onClick={() => keyPress("X")}>
						X
					</button>
					<button className={keyboard.C} onClick={() => keyPress("C")}>
						C
					</button>
					<button className={keyboard.V} onClick={() => keyPress("V")}>
						V
					</button>
					<button className={keyboard.B} onClick={() => keyPress("B")}>
						B
					</button>
					<button className={keyboard.N} onClick={() => keyPress("N")}>
						N
					</button>
					<button className={keyboard.M} onClick={() => keyPress("M")}>
						M
					</button>
				</div>
				<div>
					<button onClick={() => keyPress("Enter")}>Enter</button>
					<button onClick={() => keyPress("Backspace")}>Delete</button>
				</div>
			</div>
		</div>
	);
};

export default WordleGame;
