import { Link } from "react-router-dom";
import "./university-hub.css";

const UniversityHub = () => {
	return (
		<div id="university-hub">
			<div className="content">
				<p>This page contains a collection of my personal</p>
				<h1>University notes and projects</h1>
				<p>that I produced during my studies. Feel free to use them for studying or any other purposes. Attribution is appreciated but not required.</p>
				<h2>Notes</h2>
				<div id="notes" className="list">
					<a href="../media/docs/university/Graph_data_structure_and_algorithms.pdf" target="_blank" rel="noopener noreferrer">
						Graph data structure and algorithms
					</a>
				</div>
				<h2>Projects</h2>
				<div id="projects" className="list">
					<Link to="/university/software-verification">Static analysis</Link>
				</div>
				<Link to="/">Return to the home page.</Link>
			</div>
		</div>
	);
};

export default UniversityHub;
