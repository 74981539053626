import { Token } from "../token";
import { ArithmeticExpression, Variable } from "./arithmetic_expression";
import { BooleanExpression } from "./boolean_expression";


export abstract class Statement {
}

export class Assignment extends Statement {
  variable: Variable;
  value: ArithmeticExpression;

  constructor(
    variable: Variable,
    value: ArithmeticExpression,
  ) {
    super();
    this.variable = variable;
    this.value = value;
  };

  toString(): string {
    return `s[${this.variable.toString()} -> A[${this.value.toString()}]s]`;
  }

}

export class Skip extends Statement {
  toString(): string {
    return "id";
  }

}

export class Concatenation extends Statement {
  firstStatement: Statement;
  secondStatement: Statement;

  constructor(firstStatement: Statement, secondStatement: Statement) {
    super();
    this.firstStatement = firstStatement;
    this.secondStatement = secondStatement;
  };

  toString(): string {
    return `${this.secondStatement.toString()} ∘ ${this.firstStatement.toString()}`;
  }

}

export class IfThenElse extends Statement {
  guard: BooleanExpression;
  thenBranch: Statement;
  elseBranch: Statement;

  constructor(

    guard: BooleanExpression,
    thenBranch: Statement,
    elseBranch: Statement,
  ) {
    super();
    this.guard = guard;
    this.thenBranch = thenBranch;
    this.elseBranch = elseBranch;
  };

  toString(): string {
    return `cond( B[${this.guard.toString()}]s, Sds[${this.thenBranch.toString()}]s, Sds[${this.elseBranch.toString()}]s )`;
  }
}

export abstract class Loop extends Statement {
  body: Statement;
  guard: BooleanExpression;

  constructor(body: Statement, guard: BooleanExpression) {
    super();
    this.body = body;
    this.guard = guard;
  };
}

export class WhileLoop extends Loop {

  toString(): string {
    return `{ FIX F | (F g) s = cond(B[ ${this.guard.toString()} ]s, g ∘ ${this.body.toString()}, id) }`;
  }
}

export class RepeatUntilLoop extends Loop {

  toString(): string {
    return `{ FIX F | (F g) s = cond(B[ ${this.guard.toString()} ]s, g, id) ∘ Sds[[S]] }`;
  }
}

export class ForLoop extends Loop {
  initialStatement: Statement;
  incrementStatement: Statement;

  constructor(
    body: Statement,
    guard: BooleanExpression,
    initialStatement: Statement,
    incrementStatement: Statement,
  ) {
    super(body, guard);
    this.initialStatement = initialStatement;
    this.incrementStatement = incrementStatement;
  };

  toString(): string {
    return `{ (FIX F) ∘ ${this.initialStatement.toString()} | (F g) s = cond(B[ ${this.guard.toString()} ]s, g ∘ ${this.incrementStatement.toString()} ∘ ${this.body.toString()}, id) }`;
  }

}

export class ArithmeticUnaryOperator extends Statement {
  variable: Variable;
  operator: Token;

  constructor(
    variable: Variable,
    operator: Token,
  ) {
    super();
    this.variable = variable;
    this.operator = operator;
  };

  toString(): string {
    return `s[${this.variable.toString()} -> A[${this.variable.toString()} ${this.operator.value === "++" ? " + 1" : " - 1"}]s]`;
  }
}
