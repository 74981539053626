import { Token, TokenType } from "../model/token";
import { ArithmeticUnaryOperator, Assignment, Concatenation, ForLoop, IfThenElse, RepeatUntilLoop, Skip, Statement, WhileLoop } from "../model/while+/statement";
import { Boolean, BooleanBinaryOperator, BooleanConcatenation, BooleanExpression, BooleanUnaryOperation } from "../model/while+/boolean_expression";
import { Variable, ArithmeticBinaryOperator, ArithmeticExpression, Numeral } from "../model/while+/arithmetic_expression";
import { ProgramState } from "../components/FirstAssignment/model/program_state";
import { InitialStateFormatError as StateFormatError, ProgramFormatError, ProgramStateFormatError } from "../model/errors";
import { AbstractProgramState } from "../components/SecondAssignment/model/abstract_program_state";
import { IntervalFactory } from "../components/SecondAssignment/business/IntDomain/interval";
export class Parser {

    private static parseBool(i: string): boolean {
        if ([true, 'true', 'True', 'TRUE', '1', 1].includes(i)) return true;
        if ([false, 'false', 'False', 'FALSE', '0', 0].includes(i)) return false;
        throw Error(`"${i}" can't be parsed as boolean.`);
    }

    private static parseAtomic(input: Array<Token>): Array<any> {
        var res: Array<any> = [];
        input.forEach((element) => {
            if (element.type === TokenType.VAR) {
                res.push(new Variable(element.value));
            } else if (element.type === TokenType.NUM) {
                res.push(new Numeral(parseInt(element.value)));
            } else if (element.type === TokenType.TRUE || element.type === TokenType.FALSE) {
                res.push(new Boolean(this.parseBool(element.value)));
            } else if (element.type === TokenType.SKIP) {
                res.push(new Skip());
            } else {
                res.push(element);
            }
        });
        return res;
    }

    private static parseOperators(tokens: Array<any>): void {
        for (let i: number = 0; i < tokens.length; i++) {
            try {
                let t = tokens[i] as Token;
                if (t.type === TokenType.BBOP && t.value !== "&&" && t.value !== "||") {
                    let leftOp = tokens[i - 1] as ArithmeticExpression;
                    let rightOp = tokens[i + 1] as ArithmeticExpression;
                    tokens[i] = new BooleanBinaryOperator(
                        leftOp,
                        rightOp,
                        tokens[i],
                    );
                    tokens.splice(i + 1, 1);
                    tokens.splice(i - 1, 1);
                    i--;
                }
                if (t.type === TokenType.ABOP) {
                    let leftOp = tokens[i - 1] as ArithmeticExpression;
                    let rightOp = tokens[i + 1] as ArithmeticExpression;
                    tokens[i] = new ArithmeticBinaryOperator(leftOp, rightOp, tokens[i]);
                    tokens.splice(i + 1, 1);
                    tokens.splice(i - 1, 1);
                    i--;
                }
            } catch (e) {
            }
        }
        for (let i: number = 0; i < tokens.length; i++) {
            try {
                let t = tokens[i] as Token;
                if (t.type === TokenType.BUOP) {
                    let bExp = tokens[i + 2] as BooleanExpression;
                    tokens[i] = new BooleanUnaryOperation(bExp, tokens[i]);
                    tokens.splice(i + 1, 3);
                }
                // ? : Possible upgrade into postfix and prefix unary operators
                if (t.type === TokenType.AUOP) {
                    let v = tokens[i - 1] as Variable;
                    tokens[i] = new ArithmeticUnaryOperator(v, tokens[i]);
                    tokens.splice(i - 1, 1);
                    i--;
                }
            } catch (e) {
            }
        }
        for (let i: number = 0; i < tokens.length; i++) {
            try {
                let t = tokens[i] as Token;
                if (t.type === TokenType.BBOP) {
                    let leftExp = tokens[i - 1] as BooleanExpression;
                    let rightExp = tokens[i + 1] as BooleanExpression;
                    tokens[i] = new BooleanConcatenation(
                        leftExp,
                        rightExp,
                        tokens[i],
                    );
                    tokens.splice(i + 1, 1);
                    tokens.splice(i - 1, 1);
                    i--;
                }
                if (t.type === TokenType.ABOP) {
                    let leftOp = tokens[i - 1] as ArithmeticExpression;
                    let rightOp = tokens[i + 1] as ArithmeticExpression;
                    tokens[i] = new ArithmeticBinaryOperator(
                        leftOp,
                        rightOp,
                        tokens[i],
                    );
                    tokens.splice(i + 1, 1);
                    tokens.splice(i - 1, 1);
                    i--;
                }
            } catch (e) {
            }
        }
    }

    private static parseAssignment(tokens: Array<any>) {
        for (let i: number = 0; i < tokens.length; i++) {
            try {
                let t = tokens[i] as Token;
                if (t.type === TokenType.ASS) {
                    let v = tokens[i - 1] as Variable;
                    let aExp = tokens[i + 1] as ArithmeticExpression;
                    tokens[i] = new Assignment(
                        v,
                        aExp,
                    );
                    tokens.splice(i + 1, 1);
                    tokens.splice(i - 1, 1);
                    i--;
                }
            } catch (e) {

            }
        }
    }

    private static parseRic(tokens: Array<any>, offset: number = 0) {
        let pc: number = 0;
        for (let i: number = offset; i < tokens.length && pc >= 0; i++) {
            if (tokens[i] instanceof Token) {
                let t = tokens[i] as Token;
                if (t.type === TokenType.BRA || t.type === TokenType.bra) pc++;
                else if (t.type === TokenType.KET || t.type === TokenType.ket) pc--;
                else if (t.type === TokenType.IF) {
                    // IF - bra - Bexp - ket - THEN - BRA - Stmt - KET - ELSE - BRA - Stmt - KET
                    this.parseRic(tokens, i + 6);
                    this.parseRic(tokens, i + 10);
                    let guard: BooleanExpression = tokens[i + 2] as BooleanExpression;
                    let t = tokens[i + 6] as Statement;
                    let e = tokens[i + 10] as Statement;
                    tokens[i] = new IfThenElse(guard, t, e);
                    tokens.splice(i + 1, 11);
                }
                else if (t.type === TokenType.CONC) {
                    let first = tokens[i - 1] as Statement;
                    if (tokens[i + 1] instanceof Token) {
                        this.parseRic(tokens, i + 1);
                    }
                    let second = tokens[i + 1] as Statement;
                    tokens[i] = new Concatenation(first, second);
                    tokens.splice(i + 1, 1);
                    tokens.splice(i - 1, 1);
                    i--;
                }
                else if (t.type === TokenType.WHILE) {
                    // WHILE - bra - BExp - ket - BRA - Stmt - KET
                    this.parseRic(tokens, i + 5);
                    let guard: BooleanExpression = tokens[i + 2] as BooleanExpression;
                    let body = tokens[i + 5];
                    tokens[i] = new WhileLoop(body, guard);
                    tokens.splice(i + 1, 6);
                }
                else if (t.type === TokenType.REPEAT) {
                    // REPEAT - BRA - Stmt - KET - UNTIL - bra - Bexp - ket
                    this.parseRic(tokens, i + 2);
                    let body = tokens[i + 2] as Statement;
                    let guard = tokens[i + 6] as BooleanExpression;
                    tokens[i] = new RepeatUntilLoop(body, guard);
                    tokens.splice(i + 1, 7);
                }
                else if (t.type === TokenType.FOR) {
                    // FOR - bra - SStmt - CONC - Bexp - CONC - SStmt - ket - BRA - Stmt - KET 
                    this.parseRic(tokens, i + 9);
                    let body = tokens[i + 9] as Statement;
                    let guard = tokens[i + 4] as BooleanExpression;
                    let initialStatement = tokens[i + 2] as Statement;
                    let incrementStatement = tokens[i + 6] as Statement;
                    tokens[i] = new ForLoop(body, guard, initialStatement, incrementStatement);
                    tokens.splice(i + 1, 10);
                }
                else throw new ProgramFormatError(`Error on token ${i} : ${tokens[i].value}`);
            }
        }
    }

    static parse(input: Array<Token>): Statement {
        var tokens: Array<any> = Parser.parseAtomic(input);
        this.parseOperators(tokens);
        this.parseAssignment(tokens);
        this.parseRic(tokens);
        if (tokens[0] instanceof Statement) return tokens[0];
        throw new ProgramFormatError("Error while parsing the program.");
    }

    static parseInitialState(input: Array<Token>): ProgramState {
        // Declare
        let checkList: Array<Array<TokenType>> = [
            [TokenType.VAR],
            [TokenType.ASS],
            [TokenType.NUM, TokenType.FALSE, TokenType.TRUE],
            [TokenType.CONC]
        ];
        let res: ProgramState = new ProgramState();

        // Begin
        if (input.length % (checkList.length) !== 0) {
            throw new StateFormatError("Missed some token, check the grammar above.");
        }
        for (let i: number = 0; i < input.length; i++) {
            if (!(checkList[i % checkList.length]).includes(input[i].type)) {
                throw new StateFormatError(`Error on token ${i}: ${input[i].value}.`);
            } else if (i > 0 && i % (checkList.length) === 3) {
                if (res.contains(input[i - 3].value)) {
                    throw new StateFormatError(`Multiple definition of the same variable: ${input[i - 3].value}.`);
                } else {
                    res.set(input[i - 3].value, parseInt(input[i - 1].value), true);
                }
            }
        }

        // End
        return res;
    }

    static parseAbstractState(input: Array<Token>, intervalFactory: IntervalFactory): AbstractProgramState {
        // Declare
        let checkList: Array<Array<TokenType>> = [
            [TokenType.VAR],
            [TokenType.COLON],
            [TokenType.BBOX],
            [TokenType.NUM],
            [TokenType.COMMA],
            [TokenType.NUM],
            [TokenType.BOXB],
            [TokenType.COMMA]
        ];

        let ret: AbstractProgramState = new AbstractProgramState();
        // Begin

        // Check on format
        input.forEach((token, index) => {
            if (!checkList[index % checkList.length].includes(token.type)) throw new ProgramStateFormatError(`Wrong format: expected ${new Token(checkList[index % checkList.length][0], "").toString()}, found ${token.value}`);
        });
        if (!(input.length === 7) && !(input.length % checkList.length === 7))
            throw new ProgramStateFormatError(`Wrong format: expected a ${new Token(checkList[input.length % checkList.length][0], "").toString()}.`)

        // Well formatted
        if (input.length === 7) {
            let v: string = input[0].value;
            let lb: number = parseInt(input[3].value);
            let ub: number = parseInt(input[5].value);
            ret.set(v, intervalFactory.getInterval(lb, ub), true);
        } else {
            // v: 0 - 1+8*(block number)
            for (var i = 0; i < Math.floor(input.length / checkList.length) + 1; i++) {
                let v: string = input[0+i*8].value;
                let lb: number= parseInt(input[3+i*8].value);
                let ub: number = parseInt(input[5+i*8].value);
                ret.set(v, intervalFactory.getInterval(lb, ub), true);
            }
        }
        return ret;
        
    }
}
