import { useState } from "react";
import Latex from "react-latex-next";

import CodeEditor from "react-textarea-code-editor-2";

import "./SecondAssignment.css";
import { Lexer } from "../../business/lexer";
import { Parser } from "../../business/parser";
import { IntervalFactory } from "./business/IntDomain/interval";
import { AbstractProgramState } from "./model/abstract_program_state";
import { ProgramStateFormatError } from "../../model/errors";
import { Statement } from "../../model/while+/statement";
import { IntervalDomain } from "./business/IntDomain/intervals_domain";

const SecondAssignment = () => {
	const [input, setInput] = useState({ program: "", abstractState: "", m: "", n: "", variables: [] as Array<{ name: string; b: string }> });
	const [results, setResults] = useState({ programStatement: "", abstractProgramState: "", dSharpResult: "" });
	const [errors, setErrors] = useState({ programFormatError: "", asbtractStateFormatError: "" });

	const _submit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setErrors({ programFormatError: "", asbtractStateFormatError: "" });
		let intervalMN: IntervalFactory = new IntervalFactory();
		let intervalDomain: IntervalDomain = new IntervalDomain(intervalMN);
		let abstractState: AbstractProgramState;
		let program: Statement;
		let dSharpResult: AbstractProgramState;
		try {
			abstractState = Parser.parseAbstractState(Lexer.tokenize(input.abstractState), intervalMN);
			program = Parser.parse(Lexer.tokenize(input.program));
			dSharpResult = intervalDomain.dSharp(program, abstractState);
			console.log(dSharpResult);

			setResults((prevState) => ({ ...prevState, abstractProgramState: abstractState.toString(), programStatement: program.toString(), dSharpResult: dSharpResult.toString()}));
		} catch (e) {
			console.log(e);
			if (e instanceof ProgramStateFormatError) {
				setErrors((prevState) => ({ ...prevState, asbtractStateFormatError: (e as ProgramStateFormatError).message }));
			}
		}
	};

	return (
		<div className="second-assignment">
			<h2>Second assignment</h2>
			<p>
				<Latex>
					{
						"Implement an abstract interpreter for the abstract denotational semantics $D^\\#$ of While (cf. Chapter 4 of the lecture notes), where the language includes arithmetic expressions that may give rise to run-time errors, such as integer divisions <em>a1 % a2</em>, and may modify the current state, such as variable increments <em>x++</em> and decrements <em>x--</em>."
					}
				</Latex>
			</p>
			<p>
				<Latex>{"More in detail, this means to write a program $\\textit{AI}$, in a suitable programming language of free choice, such that:"}</Latex>
			</p>
			<ul>
				<li>
					<p>
						<Latex>
							{
								"The abstract interpreter $\\textit{AI}$ can be instantianted to a numerical abstract domain $A$ which abstracts $℘(\\Z)$ and to a state abstract domain $S$ which abstracts $℘(State)$ and is automatically derived from $A$ as a non-relational variable-wise abstract domain, so that the instantiation to $A$ is denoted by $AI_A$. One can assume that the abstract domain $A$ is a complete lattice so that $A$ is defined together with its partial order relation, bottom and top elements, lub, glb, widening and narrowing operators if needed."
							}
						</Latex>
					</p>
				</li>
				<li>
					<p>
						<Latex>
							{
								"$AI_A$ takes as input any program $P \\in While$ and abstract state $s^\\# \\in S$ (for the variables occurring in $P$ ). $AI_A(P, s^\\#)$ provides as output $D^\\#[\\![P]\\!]s^\\#$. As an optional task, $AI_A(P, s^\\#)$ may also provide as output the abstract loop invariants for any loop occurring in $P$ , which are computed by $AI_A(P, s^\\#)$ in order to compute its output $D^\\#[\\![P]\\!]s^\\#$ (therefore this goal could be achieved by suitably storing the needed information for computing $AI_A(P, s^\\#)$)."
							}
						</Latex>
					</p>
				</li>
				<li>
					<p>
						<Latex>{"Instantiate $AI$ to the following parametric restriction of the interval abstract domain $Int$:"}</Latex>
					</p>
					<p>
						<Latex>{"$Given \\space m, n \\in Z \\cup \\{-\\infty, +\\infty\\},\\space define$"}</Latex>
					</p>
					<p>
						<Latex>
							{
								"$Int_{m,n} \\overset{def}{=} \\{\\empty, \\Z \\} \\cup \\{[k, k] \\space | \\space k \\in \\Z \\} \\cup \\{[a, b] \\space | \\space a < b, [a, b] \\subseteq [m, n]\\} \\cup \\{(-\\infty, k] \\space | \\space k \\in [m, n]\\} \\cup \\{[k, -\\infty) \\space | \\space k \\in [m, n]\\} $"
							}
						</Latex>
					</p>
					<p>
						<Latex>
							{
								"Thus, we have that $Int_{-\\infty,+\\infty}$ = $Int$ and if $m > n$ then $Int_{m,n}$ is the constant propagation domain. If $m, n \\in \\Z$ then $Int_{m,n}$ has no infinite ascending chains, thus an analysis with $Int_{m,n}$, at least in principle, would not need a widening. In order to run an analysis with $Int_{m,n}$, first the user must instantiate the parameters $m$ and $n$. One could also automatically infer the parameters $m$ and $n$ from a simple syntactic analysis of the input program, e.g. based on some suitable heuristics."
							}
						</Latex>
					</p>
				</li>
			</ul>
			<h3>Submission</h3>
			<h4>Instructions and notes</h4>
			<p>
				<em>Note:</em> There isn't any coherence check on the input.
			</p>
			<p>
				<em>Note:</em> ℕ is to be intended as {"{ x | -9007199254740991 < x < 9007199254740991 }"}, due to maximum safe value for javascript version of integers.
			</p>
			<div className="syntaxes">
				<div className="initial-state-syntax">
					<h5>Abstract state grammar</h5>
					<p>
						<Latex>{"$n \\in \\N \\cup \\{-\\inf\\} \\cup \\{+\\inf\\}$"}</Latex>
					</p>
					<p>
						<Latex>{"$var \\in [a-z]+$"}</Latex>
					</p>
					<p>
						<Latex>{"$Int \\Coloneqq [n,n] \\space | \\space top \\space | \\space bottom $"}</Latex>
					</p>
					<p>
						<Latex>{"$S(var) \\Coloneqq \\space var : Int \\space | \\space S(var), S(var') $"}</Latex>
					</p>
				</div>
				<div className="while-plus-syntax">
					<h5>While grammar</h5>
					<p>
						<Latex>{"$n \\in \\N$"}</Latex>
					</p>
					<p>
						<Latex>{"$x \\in [a-z]+$"}</Latex>
					</p>
					<p>
						<Latex>{"$\\triangle \\Coloneqq \\quad != \\space | \\space == \\space | \\space < \\space | \\space <= \\space | \\space > \\space | \\space >=$"}</Latex>
					</p>
					<p>
						<Latex>{"$\\square \\Coloneqq \\space \\&\\& \\space | \\space ||$"}</Latex>
					</p>
					<p>
						<Latex>{"$\\Diamond \\Coloneqq \\space + \\space | \\space - \\space | \\space * \\space | \\space \\% \\space | \\space /$"}</Latex>
					</p>
					<p>
						<Latex>{"$A,Q \\Coloneqq \\space n \\space | \\space x \\space | \\space A \\space \\Diamond \\space Q $"}</Latex>
					</p>
					<p>
						<Latex>{"$B,R \\Coloneqq \\space true \\space | \\space false \\space | \\space A \\space \\triangle \\space Q \\space | \\space B \\space \\square \\space R$"}</Latex>
					</p>
					<p>
						<Latex>{"$S,T \\Coloneqq \\space A \\space | \\space B \\space | \\space x=A \\space | \\space skip \\space | \\space x++ \\space | \\space x-- $"}</Latex>
					</p>
					<p>
						<Latex>{"$While,P \\Coloneqq \\space S;T $"}</Latex>
					</p>
					<p>
						<Latex>{"$\\quad\\quad\\quad\\quad\\quad |\\space if(B)\\space then \\space \\{\\space P \\space\\}\\space else\\space \\{\\space P\\space \\} $"}</Latex>
					</p>
					<p>
						<Latex>{"$\\quad\\quad\\quad\\quad\\quad |\\space while\\space (B)\\space \\{\\space P \\space\\}$"}</Latex>
					</p>
					<p>
						<Latex>{"$\\quad\\quad\\quad\\quad\\quad |\\space repeat\\space\\{\\space P \\space\\}\\space until \\space(B)$"}</Latex>
					</p>
					<p>
						<Latex>{"$\\quad\\quad\\quad\\quad\\quad |\\space for \\space (S;B;T) \\space \\{\\space P \\space\\}$"}</Latex>
					</p>
				</div>
			</div>
			<h4>Examples</h4>
			<h4>Input</h4>
			<form onSubmit={_submit}>
				<label>
					While program:
					<CodeEditor
						required
						value={input["program"]}
						language="c++"
						placeholder="Use the grammar above and write a While program."
						onChange={(event) => setInput((prevState) => ({ ...prevState, program: event.target.value }))}
						padding={15}
						style={{
							fontSize: 12,
							fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
							border: "1px solid black",
							borderRadius: "5px",
							width: "100%",
							margin: "10px auto 0 auto",
							borderColor: errors.programFormatError.length > 0 ? "red" : "",
							transition: "border-color .1s ease-in-out",
						}}
					/>
					{errors.programFormatError.length > 0 && <p className="error">{errors.programFormatError}</p>}
				</label>
				<label>
					Abstract state:
					<CodeEditor
						required
						value={input.abstractState}
						language="c++"
						placeholder="Write an abstract state for the variable of the program."
						onChange={(evn) => setInput((prevState) => ({ ...prevState, abstractState: evn.target.value }))}
						padding={15}
						style={{
							fontSize: 12,
							fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
							border: "1px solid black",
							borderRadius: "5px",
							width: "100%",
							margin: "10px auto 0 auto",
							borderColor: errors.asbtractStateFormatError.length > 0 ? "red" : "",
							transition: "border-color .1s ease-in-out",
						}}
					/>
					{errors.asbtractStateFormatError.length > 0 && <p className="error">{errors.asbtractStateFormatError}</p>}
				</label>
				<button>Test</button>
			</form>
			{results.abstractProgramState.length > 0 && (
				<div className="result">
					<h4>Results</h4>
					<p aria-label="Initial state:">{results.abstractProgramState.replace(/ /g, "\u00A0")}</p>
					{/* <p aria-label="Token list:">{tokenList.length > 0 ? tokenList.toString() : "[ ]"}</p> */}
					<p aria-label="Statement:">{results.programStatement}</p>
					<p aria-label="Evaluation result:">{results.dSharpResult}</p>
				</div>
			)}
		</div>
	);
};

export default SecondAssignment;
