// Function to get a word based on the current date
const getWordForToday = (wordList: string[]): string => {
    const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    const seed = Array.from(today).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const random = seededRandom(seed);

    // Select one word from the list
    const index = Math.floor(random() * wordList.length);
    return wordList[index];
};

// Seeded random function to ensure consistent results for the same date
const seededRandom = (seed: number) => {
    return function () {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    };
};

export default getWordForToday;