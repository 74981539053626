import { Link } from "react-router-dom";
import "./404.css";
const FourOFour = () => {
	return (
		<div id="Four-O-Four">
			<div>
				<p>Sir, there is nothing to see here.</p>
				<Link to="/">May I escort you to the home page?</Link>
			</div>
		</div>
	);
};

export default FourOFour;
