import { useState } from "react";
import "./InstructionModal.css";

const InstructionModal = () => {
	const [isOpen, toggleOpen] = useState(false);

	const toggle = () => {
		toggleOpen((prevState) => !prevState);
	};

		return (
			<div id="modal">
				<button id="close-button" onClick={() => toggleOpen((prevState) => !prevState)}>Instrctions</button>
				<div
					id="instruction-modal"
					onClick={(e) => {
						if (e.target === e.currentTarget) toggle();
					}}
					className={isOpen.toString()}
				>
					<div id="instruction" className={isOpen.toString()}>
						<button onClick={() => toggle()}>Close</button>
						<h2>How to play</h2>
						<ul>
							<li>Guess the word in 6 tries</li>
							<li>Each guess must be a valid five letter word,</li>
							<li>The color of the tiles will change to show how close your guess was to the word as described below.</li>
						</ul>
						<h3>How to read tiles color</h3>
						<div className="example">
							<p className="row">
								<span aria-label="exact" className="exact">
									H
								</span>
								<span aria-label="miss" className="miss">
									O
								</span>
								<span aria-label="miss" className="miss">
									U
								</span>
								<span aria-label="miss" className="miss">
									S
								</span>
								<span aria-label="miss" className="miss">
									E
								</span>
							</p>
							<p>The letter H is in the word and it's in the correct position.</p>
						</div>
						<div className="example">
							<p className="row">
								<span aria-label="miss" className="miss">
									M
								</span>
								<span aria-label="correct" className="correct">
									A
								</span>
								<span aria-label="miss" className="miss">
									Y
								</span>
								<span aria-label="correct" className="correct">
									B
								</span>
								<span aria-label="miss" className="miss">
									E
								</span>
							</p>
							<p>The letter H is in the word and it's in the correct position.</p>
						</div>
						<div className="example">
							<p className="row">
								<span aira-lable="miss" className="miss">
									H
								</span>
								<span aria-label="miss" className="miss">
									O
								</span>
								<span aria-label="miss" className="miss">
									U
								</span>
								<span aria-label="miss" className="miss">
									S
								</span>
								<span aria-label="miss" className="miss">
									E
								</span>
							</p>
							<p>None of the letters is in the word.</p>
						</div>
						<hr />
						<p>A new puzzle is released daily at midnight from a list of nearly 2.5k words.</p>
					</div>
				</div>
			</div>
		);
};
export default InstructionModal;
