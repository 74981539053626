export enum TokenType {
  // Numeral
  NUM,
  // Variable
  VAR,
  // Booleans
  TRUE,
  FALSE,

  // Arithmetic operator
  ABOP,
  AUOP,

  // Binary operators
  BBOP,
  BUOP,

  // Parenthesis and braces
  BRA,
  bra,
  KET,
  ket,

  // Base cases
  ASS,
  SKIP,
  // Concatenation
  CONC,
  // Conditional
  IF,
  THEN,
  ELSE,
  // While loop
  WHILE,
  // Repeat-until loop
  REPEAT,
  UNTIL,
  // For loop
  FOR,

  ERROR,

  // AbstractStates
  COLON,
  TOP,
  BOXB,
  BBOX,
  COMMA
}


export class Token {
  private _type: TokenType;
  private _value: string;

  private tokenTypeToString = [
    // Numeral
  "Numeral",
  // Variable
  "Variable",
  // Booleans
  "tt",
  "ff",

  // Arithmetic operator
  "ABOP",
  "AUOP",

  // Binary operators
  "BBOP",
  "BUOP",

  // Parenthesis and braces
  "{",
  "(",
  "}",
  ")",

  // Base cases
  "=",
  "skip",
  // Concatenation
  ";",
  // Conditional
  "if",
  "then",
  "else",
  // While loop
  "while",
  // Repeat-until loop
  "repeat",
  "until",
  // For loop
  "for",

  "error",
  ":",
  "top",
  "[",
  "]",
  ","
  ];
  
  constructor(type: TokenType, value: string) {
    this._type = type;
    this._value = value;
  };

  public toString(): string {
    return `" ${this.tokenTypeToString[this._type]} "`;
  }

  public get type(): TokenType { return this._type; }
  public get value(): string { return this._value; }
}
