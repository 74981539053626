import { useEffect, useState } from "react";
import WordleGame from "../components/WordleGame/view/WordleGame";
import InstructionModal from "../components/InstructionsModal/view/InstructionModal";

import "./Wordle.css";
import getWordForToday from "../logic/wordsGetter";
import wordsDictionary from "../data/words_dictionary.json";
import { ClipLoader } from "react-spinners";

enum States {
	LOADING,
	READY,
	ERROR,
}

const Wordle = () => {
	const [word, setWord] = useState<string>("");
	const [wordList, setWordList] = useState<Array<string>>([]);
	const [state, setState] = useState<States>(States.LOADING);

	useEffect(() => {
		try {
			const allWords = Object.keys(wordsDictionary);
			setWordList(allWords);
			const selectedWord = getWordForToday(allWords);
			setWord(selectedWord);
			setState(States.READY)
		} catch {
			setState(States.ERROR);
		}
	}, []);

	return (
		<div id="wordle-page">
			<header>
				<h1>Wordle</h1>
				<InstructionModal></InstructionModal>
			</header>
			{state === States.LOADING && <ClipLoader color={"#FFFFFF"} size={100} aria-label="Loading Spinner" data-testid="loader" />}
			{state === States.ERROR && <div>Error loading data. Please try again later.</div>}
			{state === States.READY && <WordleGame _word={word} _wordList={wordList} />}
		</div>
	);
};

export default Wordle;
