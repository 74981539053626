
import { ArithmeticUnaryOperator, Assignment, Concatenation, ForLoop, IfThenElse, RepeatUntilLoop, Skip, Statement, WhileLoop } from "../../../model/while+/statement";
import { ProgramState } from "../model/program_state";
import A from "./a";
import B from "./b";
class Sds{
    static eval(stmt: Statement, state: ProgramState, iterationLimit: number): ProgramState {

        if (stmt instanceof Assignment) {
            let aux: ProgramState = state.copy();
            aux.set(stmt.variable.name, A.eval(stmt.value, state));
            return aux;
        }
    
        if (stmt instanceof Skip) {
            return state.copy();
        }
    
        if (stmt instanceof Concatenation) {
            return Sds.eval(stmt.secondStatement, Sds.eval(stmt.firstStatement, state, iterationLimit), iterationLimit);
        }
    
        if (stmt instanceof IfThenElse) {
            if (B.eval(stmt.guard, state))
                return Sds.eval(stmt.thenBranch, state.copy(), iterationLimit);
            else
                return Sds.eval(stmt.elseBranch, state.copy(), iterationLimit);
        }
    
        if (stmt instanceof WhileLoop) {
            // Declare
            let currentState: ProgramState = state.copy();
            let prevState: ProgramState;
            let counter: number = 0;
    
            // Begin
            do {
                prevState = currentState;
                if (B.eval(stmt.guard, currentState)) {
                    currentState = Sds.eval(stmt.body, currentState, iterationLimit);
                }
                console.log(prevState.toString(), currentState.toString());
                counter++;
            } while (prevState !== currentState && counter < iterationLimit);
            return currentState;
        }
    
        if (stmt instanceof RepeatUntilLoop) {
            // Declare
            let currentState: ProgramState = state.copy();
            let prevState: ProgramState = currentState;
            let counter: number = 0;
    
            // Begin
            currentState = Sds.eval(stmt.body, currentState, iterationLimit);
            do {
                prevState = currentState;
                if (B.eval(stmt.guard, state)) {
                    currentState = Sds.eval(stmt.body, currentState, iterationLimit);
                }
                counter++;
                console.log(prevState.toString(), currentState.toString());
            } while (prevState !== currentState && counter < iterationLimit);
            return currentState;
        }
    
        if (stmt instanceof ForLoop) {
            // Declare
            let currentState: ProgramState = state.copy();
            let prevState: ProgramState;
            let counter: number = 0;
    
            // Begin
            currentState = Sds.eval(stmt.initialStatement, currentState, iterationLimit);
            do {
                prevState = currentState;
                if (B.eval(stmt.guard, currentState)) {
                    currentState = Sds.eval(stmt.incrementStatement, Sds.eval(stmt.body, currentState, iterationLimit), iterationLimit);
                }
                counter++;
                console.log(prevState.toString(), currentState.toString());
            } while (prevState !== currentState && counter < iterationLimit);
            return currentState;
        }
    
        if (stmt instanceof ArithmeticUnaryOperator) {
            let aux: ProgramState = state.copy();
            switch (stmt.operator.value) {
                case "++":
                    aux.set(stmt.variable.name, state.get(stmt.variable.name) + 1);
                    return aux;
                case "--":
                    aux.set(stmt.variable.name, state.get(stmt.variable.name) - 1);
                    return aux;
                default:
                    throw Error("Illegal arithmetic unary operator.");
            }
        }
        throw Error;
    };
}
export default Sds;